$(document).ready(function () {
    $('#openmap-carousel').owlCarousel({
        loop:true,
        margin:10,
        // nav:true,
        dots:false,
        autoplay:true,
        autoplayTimeout:3000,
        mouseDrag:false,
        touchDrag:false,
        responsive:{
            0:{
                items:1
            },
        }
    });
    $('#article-carousel').owlCarousel({
        loop:true,
        margin:20,
        nav:true,
        dots:false,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },
            1000:{
                items:4
            }
        }
    });
    $('#utilityStory-carousel,#utilityAPP-carousel').owlCarousel({
        loop:true,
        margin:16,
        nav:true,
        dots:false,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },
            1000:{
                items:3
            }
        }
    });
    $('#opendata-table').DataTable({
        lengthChange: false,
        searching: false,
        ordering:  false,
        info: false,
        pageLength: 5,
        paging: true,
        language: {
            paginate: {
                previous: "<span class='myicon icon-arrow-left'></span>",
                next:     "<span class='myicon icon-arrow-right'></span>"
            }
        },
    });
    $('#casestudy-table').DataTable({
        lengthChange: false,
        searching: true,
        ordering:  false,
        info: false,
        pageLength: 3,
        paging: true,
        language: {
            paginate: {
                previous: "<span class='myicon icon-arrow-left'></span>",
                next:     "<span class='myicon icon-arrow-right'></span>"
            },
            search: "搜尋",
        },
    });
    $('#multiple-publisher').multiselect({
      includeSelectAllOption: true,
      nonSelectedText: '發布單位 Publisher',
    });
    $('#multiple-nation').multiselect({
      includeSelectAllOption: true,
      nonSelectedText: '國家/區域 Country or Area',
    });
    $('#multiple-name').multiselect({
      includeSelectAllOption: true,
      nonSelectedText: '資料集名稱 Dataset',
    });
});

// custom select begin
    var x, i, j, selElmnt, a, b, c;
    /*look for any elements with the class "customized-select":*/
    x = document.getElementsByClassName("customized-select");
    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      /*for each element, create a new DIV that will act as the selected item:*/
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      /*for each element, create a new DIV that will contain the option list:*/
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < selElmnt.length; j++) {
        /*for each option in the original select element,
        create a new DIV that will act as an option item:*/
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function(e) {
            /*when an item is clicked, update the original select box,
            and the selected item:*/
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
              }
            }
            h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function(e) {
          /*when the select box is clicked, close any other select boxes,
          and open/close the current select box:*/
          e.stopPropagation();
          closeAllSelect(this);
          this.nextSibling.classList.toggle("select-hide");
          this.classList.toggle("select-arrow-active");
        });
    }
    function closeAllSelect(elmnt) {
      /*a function that will close all select boxes in the document,
      except the current select box:*/
      var x, y, i, arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }
    /*if the user clicks anywhere outside the select box,
    then close all select boxes:*/
    document.addEventListener("click", closeAllSelect);
    
// custom select end
